// src/hooks/useFetchEvents.js

import { useState, useEffect } from 'react';
import useFetchPractices from './useFetchPractices';
import useFetchGames from './useFetchGames';

const useFetchEvents = (teamId) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { practices, loading: practicesLoading, error: practicesError, refreshPractices } = useFetchPractices(teamId);
  const { games, loading: gamesLoading, error: gamesError, refreshGames } = useFetchGames(teamId);

  useEffect(() => {
    setLoading(practicesLoading || gamesLoading);
    setError(practicesError || gamesError);

    if (!practicesLoading && !gamesLoading && !practicesError && !gamesError) {
      const combinedEvents = [
        ...practices.map(event => ({ ...event, type: 'Practice' })),
        ...games.map(event => ({ ...event, type: 'Game' })),
      ];
      setEvents(combinedEvents);
    }
  }, [practices, games, practicesLoading, gamesLoading, practicesError, gamesError]);

  const refreshEvents = async () => {
    try {
      setLoading(true);
      await Promise.all([refreshPractices(), refreshGames()]);
    } catch (err) {
      setError('Fehler beim Aktualisieren der Events');
    } finally {
      setLoading(false);
    }
  };

  return { events, loading, error, refreshEvents };
};

export default useFetchEvents;
