// PlayTable.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box,
  Paper, TablePagination, TextField, InputAdornment, IconButton, TableSortLabel,
  Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from '../api/axiosInstance';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContainerPageHeader from '../components/ContainerPageHeader';

const PlayTablePage = ({ organisationId }) => {
  const { unit } = useParams();
  const [plays, setPlays] = useState([]);
  const [filteredPlays, setFilteredPlays] = useState([]);
  const [playbookTypes, setPlaybookTypes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [openDialog, setOpenDialog] = useState(false);
  const [playToDelete, setPlayToDelete] = useState(null);

  useEffect(() => {
    fetchPlays();
    fetchPlaybookTypes();
  }, [unit, organisationId]);

  // Fetch Playbook Types with show_in_menu=True
  const fetchPlaybookTypes = async () => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/menu/${unit}/playbook-types/`);
      setPlaybookTypes(response.data);
    } catch (error) {
      console.error('Error fetching playbook types:', error);
    }
  };

  const fetchPlays = async () => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/plays/?unit=${unit}`);
      setPlays(response.data);
      setFilteredPlays(response.data);
    } catch (error) {
      console.error(`Error fetching ${unit} plays:`, error);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    if (value === '') {
      setFilteredPlays(plays);
    } else {
      setFilteredPlays(plays.filter(play =>
        play.name.toLowerCase().includes(value)
      ));
    }
  };

  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...filteredPlays].sort((a, b) => {
      if (a[property] < b[property]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredPlays(sortedData);
  };

  const handleDeleteClick = (play) => {
    setPlayToDelete(play);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/playbook_management/${organisationId}/plays/${playToDelete.id}/`);
      setPlays((prevPlays) => prevPlays.filter((play) => play.id !== playToDelete.id));
      setFilteredPlays((prevPlays) => prevPlays.filter((play) => play.id !== playToDelete.id));
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting play:", error);
    }
  };

  return (
    <>
      <ContainerPageHeader title={`${unit} Plays`}>
        <TextField
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <Button
          component={Link}
          to={`/${unit}/play/new`} // Beispiel für eine Offense-Unit, könnte dynamisch gemacht werden
          variant="contained"
          color="primary"
          sx={{ marginBottom: 2 }}
        >
          Add New {unit} Play
        </Button>
      </ContainerPageHeader>
      <TableContainer>
        <Table stickyHeader aria-label="play table">
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleSortRequest('name')}
                >
                  Call
                </TableSortLabel>
              </TableCell>

              {/* Dynamische Spalten basierend auf `show_in_menu` Playbook Types */}
              {/* Beispielcode: */}
              {playbookTypes.length > 0 && playbookTypes.map((typeTag, index) => (
                <TableCell key={index} sortDirection={orderBy === typeTag.name ? order : false}>
                    <TableSortLabel
                      active={orderBy === typeTag.name}
                      direction={orderBy === typeTag.name ? order : 'asc'}
                      onClick={() => handleSortRequest(typeTag.name)}
                    >
                      {typeTag.name}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPlays.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((play, index) => (
                <TableRow key={index} hover>
                  <TableCell>{play.name}</TableCell>
                  {playbookTypes.map((column) => {
                    const typeTag = play.playbook_type_tags.find(
                      (tag) => tag.playbook_type_name === column.name
                    );
                    return (
                      <TableCell key={column.id}>
                        {typeTag && typeTag.tags_output.length > 0
                          ? typeTag.tags_output.map((tag) => tag.name).join(', ')
                          : <span style={{ color: '#aaa' }}>Keine Tags</span>}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <IconButton component={Link} to={`/${unit}/play/${play.id}`}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(play)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredPlays.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => setRowsPerPage(+event.target.value)}
        />
      {/* Confirm Delete Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the play "{playToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlayTablePage;
