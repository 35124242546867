// src/components/Timeline/EventTimeline.js
import React, { useState, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import EventCard from './EventCard';
import axiosInstance from '../../api/axiosInstance';

const EventTimeline = ({ initialEvents }) => {
  const [events, setEvents] = useState(initialEvents); 
  
  // Dialog-States
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newScriptName, setNewScriptName] = useState('');

  const sortedEvents = useMemo(() => {
    return [...events].sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [events]);

  const handleOpenDialog = (event, addScriptToEvent) => {
    setSelectedEvent({ ...event, addScriptToEvent }); // Bindet die Funktion an das Event
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewScriptName('');
  };

  const handleCreateScript = async () => {
    if (!selectedEvent) return;
    try {
      const newScript = {
        name: newScriptName || 'Neues Script'
      };

      let response;
      if (selectedEvent.event_type === 'Game') {
        response = await axiosInstance.post(`/games/${selectedEvent.id}/scripts/`, newScript);
      } else {
        response = await axiosInstance.post(`/practices/${selectedEvent.id}/scripts/`, newScript);
      }

      // Aktualisiere die Scripts-Liste im entsprechenden Event
      if (selectedEvent.addScriptToEvent) {
        console.info("add");
        selectedEvent.addScriptToEvent(response.data);
      }

      handleCloseDialog(); // Schließe den Dialog
    } catch (error) {
      console.error('Fehler beim Erstellen des Scripts:', error);
    }
  };

  return (
    <>
      {sortedEvents.map((event) => (
        <EventCard 
          key={event.id} 
          event={event}
          onAddScript={() => handleOpenDialog(event, (newScript) => {
            // Aktualisiere die Scripts für das ausgewählte Event
            setEvents((prevEvents) =>
              prevEvents.map((e) =>
                e.id === event.id ? { ...e, scripts: [...(e.scripts || []), newScript] } : e
              )
            );
          })} />
      ))}

      {/* Dialog für neues Script */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Neues Script für {selectedEvent?.name} erstellen</DialogTitle>
        <DialogContent>
          <TextField
            label="Script Name"
            variant="outlined"
            fullWidth
            value={newScriptName}
            onChange={(e) => setNewScriptName(e.target.value)}
            placeholder="Gib einen Script-Namen ein"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleCreateScript} variant="contained" color="primary">
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventTimeline;
