// Dashboard.js

import React, { useEffect, useState } from 'react';
import { Button, Typography, Container, Paper } from '@mui/material';
import PlayDiagram from './PlayDiagram';
import axiosInstance from './api/axiosInstance';

const Dashboard = ({ selectedTeam }) => {
    if (!selectedTeam) return <p>Please select a team to manage.</p>;
    
    return (
      <div className="p-4">
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
          <h1>Football Play Diagram</h1>
          <PlayDiagram />
        </Paper>
      </Container>
      </div>
    );
}

export default Dashboard;
