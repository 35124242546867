import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, TextField, Typography, Select, MenuItem, Button, Modal, FormControl, Chip, OutlinedInput, Autocomplete, Grid
} from '@mui/material';
import axiosInstance from '../api/axiosInstance';

const PlayDetailPage = ({ organisationId }) => {
  const { playId, unit } = useParams(); // Extrahiert `playId` und `unit` aus den Routen-Parametern
  const navigate = useNavigate();
  const [play, setPlay] = useState(null);
  const [playbookTypes, setPlaybookTypes] = useState([]);
  const [selectedTags, setSelectedTags] = useState({});
  const [defaultPlaybookTypes, setDefaultPlaybookTypes] = useState([]);
  const [nonDefaultPlaybookTypes, setNonDefaultPlaybookTypes] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlaybookType, setSelectedPlaybookType] = useState('');
  const [notes, setNotes] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (playId != "new") {
      fetchPlayDetails(playId);
    }    
    fetchPlaybookTypes(true);  // Lädt PlaybookTypes mit default = true
    fetchPlaybookTypes(false); // Lädt PlaybookTypes mit default = false
  }, [playId]);

  const fetchPlayDetails = async (id) => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/plays/${id}/`);
      setPlay(response.data);
      setTitle(response.data.name);
      setSelectedTags(mapTagsToState(response.data.playbook_type_tags));
      setNotes(response.data.description || '');
    } catch (error) {
      console.error('Error fetching play details:', error);
    }
  };

  const fetchPlaybookTypes = async (isDefault) => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/playbook-types/?unit=${unit}&default=${isDefault}`);
      const playbookTypesWithTags = response.data.map(type => ({
        ...type,
        tags: type.tags || [],  // Sicherstellen, dass `tags` vorhanden ist
      }));      
      if (isDefault) {
        setDefaultPlaybookTypes(playbookTypesWithTags);
      } else {
        setNonDefaultPlaybookTypes(playbookTypesWithTags);
      }
    } catch (error) {
      console.error('Error fetching playbook types:', error);
    } 
  };

  const handleModalToggle = () => setModalOpen(!isModalOpen);


  const handleSavePlaybookType = () => {
    const selectedType = nonDefaultPlaybookTypes.find(type => type.id === selectedPlaybookType);
    if (selectedType) {
      setDefaultPlaybookTypes([...defaultPlaybookTypes, selectedType]);
      setModalOpen(false);
      setSelectedPlaybookType('');
    }
  };

  const mapTagsToState = (playbookTypeTags) => {
    const tagsState = {};
    playbookTypeTags.forEach(typeTag => {
      tagsState[typeTag.playbook_type_name] = typeTag.tags_output.map(tag => ({ id: tag.id, name: tag.name, active: tag.active, description: tag.description, type: tag.type, organisation: organisationId }));
    });
    return tagsState;
  };

  const handleTagChange = (typeName, typeId, event, newValue) => {
    //const tagsToSave = newValue.map(tag => (typeof tag === 'string' ? { id: Date.now(), name: tag } : tag));
    const tagsToSave = newValue.map(tag => (typeof tag === 'string' ? { name: tag, active: true, description: "", type: typeId, organisation: organisationId } : tag));

    setSelectedTags((prev) => ({
      ...prev,
      [typeName]: tagsToSave,
    }));
    // Falls ein neues Tag (string) hinzugefügt wird, erweitere das `playbookTypes`-Array dynamisch
    const playbookTypeIndex = defaultPlaybookTypes.findIndex(type => type.name === typeName);
    if (playbookTypeIndex !== -1) {
      const updatedPlaybookTypes = [...defaultPlaybookTypes];
      tagsToSave.forEach(tag => {
          // Überprüfen, ob das Tag bereits existiert
        if (!updatedPlaybookTypes[playbookTypeIndex].tags.some(t => t.name === tag.name && t.id === tag.id)) {
          // Neuen Tag hinzufügen
          updatedPlaybookTypes[playbookTypeIndex].tags.push({ id: tag.id, name: tag.name });  // Temporäre ID
        }
        
      });
      setDefaultPlaybookTypes(updatedPlaybookTypes);
    }
  };

  const handleSave = async () => {
    // Map selectedTags to the format expected by the API
    const playbookTypeTags = Object.keys(selectedTags).map((typeName) => {
      const playbookType = defaultPlaybookTypes.find((type) => type.name === typeName);
      return {
        playbook_type_id: playbookType.id,
        playbook_type_name: playbookType.name,
        tags: selectedTags[typeName],
      };
    });
    const playData = {
      name: title,
      description: notes,
      unit: unit,
      organisation: organisationId,
      playbook_type_tags: playbookTypeTags,
    };
    try {
      if (playId != "new") {
        await axiosInstance.put(`/playbook_management/${organisationId}/plays/${playId}/`, playData);
      } else {
        await axiosInstance.post(`/playbook_management/${organisationId}/plays/`, playData);
      }
      navigate(`/plays/${unit}`);
    } catch (error) {
      console.error('Error saving play:', error);
    }
  };

  return (
    <>
      <Typography variant="h5">{playId!="new" ? 'Edit Play' : 'Add New Play'}</Typography>

      {/* Play Info Section */}
      <Grid container spacing={2} sx={{ marginTop: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Play Info</Typography>
          <TextField
            fullWidth
            label="Play Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginTop: 2 }}
          />
        </Grid>

        {/* Playbook Tags Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Playbook Tags</Typography>
          {defaultPlaybookTypes.map((type) => (
            
            <Box key={type.id} sx={{ marginTop: 2 }}>
              <Autocomplete
                multiple
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                value={selectedTags[type.name] || []}
                onChange={(event, newValue) => handleTagChange(type.name, type.id, event, newValue)}
                options={type.tags || []}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index }); // Separate key prop from others
                    return (
                      <Chip
                        key={option.id || option.name} 
                        variant="outlined"
                        label={option.name || option}
                        {...tagProps }
                      />);
                })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={type.name}
                    placeholder="Add tags"
                  />
                )}
              />
            </Box>
          ))}
          {/* Button to open Modal */}
          <Button onClick={handleModalToggle} sx={{ marginTop: 2 }} variant="outlined">
            Add Playbook Type
          </Button>
        </Grid>
      </Grid>

      {/* Notes Section */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6">Notes</Typography>
        <TextField
            label="Notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={4}
            fullWidth
            sx={{ marginBottom: 2 }}
        />      </Box>

      {/* Save Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ marginTop: 2 }}
      >
        Save
      </Button>
      {/* Modal for adding non-default Playbook Types */}
      <Modal open={isModalOpen} onClose={handleModalToggle}>
        <Box sx={{ width: 300, padding: 3, margin: 'auto', marginTop: '10%', backgroundColor: 'white', borderRadius: 1 }}>
          <Typography variant="h6">Add Playbook Type</Typography>
          <Select
            fullWidth
            value={selectedPlaybookType}
            onChange={(e) => setSelectedPlaybookType(e.target.value)}
            displayEmpty
            sx={{ marginTop: 2 }}
          >
            <MenuItem value="" disabled>
              Select a Playbook Type
            </MenuItem>
            {nonDefaultPlaybookTypes.map(type => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleSavePlaybookType} variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PlayDetailPage;
