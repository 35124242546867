// PlaybookTagsTable.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box,
  Paper, TablePagination, TextField, InputAdornment, IconButton, TableSortLabel,
  Button, Accordion, AccordionSummary, AccordionDetails, Typography, Modal, Select, MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import axiosInstance from '../api/axiosInstance';
import AddTagModal from '../components/AddTagModal';
import ContainerPageHeader from '../components/ContainerPageHeader';

const PlaybookTagsPage = ({ organisationId  }) => {
  const { unit, type } = useParams(); 
  const [openModal, setOpenModal] = useState(false); 
  const [editTag, setEditTag] = useState(null);
  const [playbookTags, setPlaybookTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name'); // Default-Sortierung nach Tag-Name
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const navigate = useNavigate();

  const [firstTypeName, setFirstTypeName] = useState('');

  // Fetch Playbook Tags based on the unit (Offense, Defense, Special)
  useEffect(() => {
    loadFirstTypeName();
    fetchTags();
  }, [unit, type, organisationId]);


  const loadFirstTypeName = async () => {
    if (type != "all") {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/playbook-types/${type}/`);
      setFirstTypeName(response.data.name);  // 'type' ID des ersten Tags
    }
  };

  const fetchTags = async () => {
    try {
      let response;
  
      if (type && type != "all") {
        response = await axiosInstance.get(`/playbook_management/${organisationId}/tags/?unit=${unit}&type=${type}`);
      } else {
        response = await axiosInstance.get(`/playbook_management/${organisationId}/tags/?unit=${unit}`);
      }
          
      setPlaybookTags(response.data);
      setFilteredTags(response.data);
    } catch (error) {
      console.error(`Error fetching ${unit} playbook tags:`, error);
    }
  };

  const handleSettingsClick = () => {
    // Navigiert zur PlaybookType-Seite
    navigate(`/terminology/types/${unit}`);
  };

  // Handle Pagination
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle Search
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    if (value === '') {
      setFilteredTags(playbookTags);
    } else {
      setFilteredTags(playbookTags.filter(tag =>
        tag.name.toLowerCase().includes(value) ||
        tag.type_name.toLowerCase().includes(value)
      ));
    }
  };

  // Sortier-Logik
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...filteredTags].sort((a, b) => {
      if (a[property] < b[property]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredTags(sortedData);
  };

  const handleAddOrEditTag = async (tagData) => {
    try {
      if (editTag) {
        // Wenn wir im Bearbeitungsmodus sind
        await axiosInstance.put(`/playbook_management/${organisationId}/tags/${editTag.id}/`, {
          ...tagData,
          organisation: organisationId,
          unit
        });
      } else {
        // Neuen Tag erstellen
        await axiosInstance.post(`/playbook_management/${organisationId}/tags/`, {
          ...tagData,
          organisation: organisationId,
          unit
        });
      }
      fetchTags(); // Tags neu laden
      setOpenModal(false);
    } catch (error) {
      console.error('Error adding/editing tag:', error);
    }
  };


  // Edit Tag Handling
  const handleEditTag = (tag) => {
    setEditTag(tag);
    setOpenModal(true);
  };

  // Delete Tag Handling
  const handleDeleteTag = async (tagId) => {
    try {
      await axiosInstance.delete(`/playbook_management/tags/${tagId}/`);
      fetchTags(); // Nach erfolgreichem Löschen die Liste neu laden
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const mapStatusToText = (status) => {
    return status ? "Aktiv" : "Nicht aktiv";
  };

  const handleGroupModalOpen = () => setGroupModalOpen(true);
  const handleGroupModalClose = () => setGroupModalOpen(false);
  const handleGroupByChange = (event) => setGroupBy(event.target.value);
  
  const groupByFunction = (array, key) => {
    return array.reduce((result, currentItem) => {
      const groupKey = key === "active" ? mapStatusToText(currentItem[key]) : currentItem[key];      
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentItem);
      return result;
    }, {});
  };

  const handleAccordionToggle = (group) => {
    setExpandedGroups((prev) =>
      prev.includes(group)
        ? prev.filter((g) => g !== group)
        : [...prev, group]
    );
  };

  const handleToggleStatus = async (tagData, currentStatus) => {
    const newStatus = currentStatus === true ? false : true; // Status umkehren
    try {
      await axiosInstance.put(`/playbook_management/${organisationId}/tags/${tagData.id}/`, {
        name: tagData.name,
        type: tagData.type,
        active: newStatus,
        organisation: organisationId,
        unit
      });
      fetchTags(); // Daten neu laden
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  return (
    <>
      <ContainerPageHeader title={firstTypeName ? `${firstTypeName} Tags` : `${unit} Playbook Tags`}>
        <TextField
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <Button
          variant="outlined"
          startIcon={<GroupIcon />}
          onClick={handleGroupModalOpen}
        >
          Group
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />} 
          onClick={() => {
            setEditTag(null);  // Resetten von `editTag`, damit klar ist, dass wir einen neuen Tag erstellen
            setOpenModal(true);
          }}
        >
          Add Tag
        </Button>

        {/* Button to open PlaybookType settings */}
        <Button
          variant="contained" 
          color="secondary" 
          startIcon={<SettingsIcon />} 
          onClick={handleSettingsClick}
        >
          Manage Types
        </Button>
      </ContainerPageHeader>
      <AddTagModal open={openModal} onClose={() => setOpenModal(false)} onAddOrEditTag={handleAddOrEditTag} existingTag={editTag} organisationId={organisationId} unit={unit} preselectedType={type !== "all" ? type : null} />

      

        {/* Modal for Grouping */}
        <Modal open={groupModalOpen} onClose={handleGroupModalClose}>
          <Box sx={{ padding: 3, backgroundColor: 'white', margin: 'auto', marginTop: '10%', width: 300, borderRadius: 2 }}>
            <Typography variant="h6">Group by</Typography>
            <Select
              value={groupBy || ""}  
              onChange={handleGroupByChange}
              fullWidth
              displayEmpty
              sx={{ marginTop: 2 }}

            >
              <MenuItem value="" >
                Wähle eine Spalte
              </MenuItem>
              <MenuItem value="type_name">Type</MenuItem>
              <MenuItem value="active">Status</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
              onClick={handleGroupModalClose}
            >
              Apply
            </Button>
          </Box>
        </Modal>

        {/* Render grouped or flat table based on "groupBy" state */}
        {groupBy ? (
          Object.entries(groupByFunction(filteredTags, groupBy)).map(([group, tags]) => (
            <Accordion key={group} expanded={expandedGroups.includes(group)} onChange={() => handleAccordionToggle(group)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{group} ({tags.length})</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table stickyHeader aria-label="playbook tags table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Tag Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tag, index) => (
                        <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                          {/* Status Badge */}
                          <TableCell>
                            <span
                              style={{
                                padding: "4px 8px",
                                borderRadius: "8px",
                                backgroundColor: tag.active === true ? "#4caf50" : "#f44336",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => handleToggleStatus(tag, tag.active)}
                            >
                              {tag.active === true ? "Aktiv" : "Nicht Aktiv"}
                            </span>
                          </TableCell>
                          <TableCell>{tag.name}</TableCell>
                          <TableCell>{tag.description}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleEditTag(tag)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteTag(tag.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="playbook tags table">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell sortDirection={orderBy === 'name' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={() => handleSortRequest('name')}
                    >
                      Tag Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={orderBy === 'type_name' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'type_name'}
                      direction={orderBy === 'type_name' ? order : 'asc'}
                      onClick={() => handleSortRequest('type_name')}
                    >
                      Type
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tag, index) => (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    {/* Status Badge */}
                    <TableCell>
                      <span
                        style={{
                          padding: "4px 8px",
                          borderRadius: "8px",
                          backgroundColor: tag.active === true ? "#4caf50" : "#f44336",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleToggleStatus(tag, tag.active)}
                      >
                        {tag.active === true ? "Aktiv" : "Nicht Aktiv"}
                      </span>
                    </TableCell>
                    <TableCell>{tag.name}</TableCell>
                    <TableCell>{tag.type_name}</TableCell>
                    <TableCell>{tag.description}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditTag(tag)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteTag(tag.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredTags.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>
  );
};

export default PlaybookTagsPage;
