// src/components/Timeline/EventCard.js
import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

const EventCard = ({ event, onAddScript, addScriptToEvent }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/events/${event.event_type.toLowerCase()}/${event.id}`);
  const [scripts, setScripts] = useState(event.scripts || []);

  const isGame = event.event_type === 'Game';
  
  const [dialogOpen, setDialogOpen] = useState(false); // Dialog-Status
  const [scriptToDelete, setScriptToDelete] = useState(null); // Das zu löschende Script


  useEffect(() => {
    const fetchScripts = async () => {
      try {
        let response;
        if (isGame) {
          response = await axiosInstance.get(`/games/${event.id}/scripts/`);
        } else {
          response = await axiosInstance.get(`/practices/${event.id}/scripts/`);
        }
        setScripts(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Scripts:', error);
      }
    };
    if (!event.scripts || event.scripts.length === 0) {
      fetchScripts();
    }
  }, [event.id, event.event_type]);

  // Aktualisiere die `scripts`-Liste, wenn `event.scripts` sich ändert
  useEffect(() => {
    if (event.scripts && event.scripts.length > 0) {
      setScripts((prevScripts) => [...prevScripts, ...event.scripts]);
    }
  }, [event.scripts]);

  
  // Hinzufügen eines neuen Scripts zur lokalen State
  const handleAddScript = (newScript) => {
    setScripts((prevScripts) => [...prevScripts, newScript]);
  };

  // Öffnet den Bestätigungsdialog
  const handleOpenDeleteDialog = (scriptId) => {
    setScriptToDelete(scriptId);
    setDialogOpen(true);
  };

  // Schließt den Bestätigungsdialog
  const handleCloseDeleteDialog = () => {
    setScriptToDelete(null);
    setDialogOpen(false);
  };

  // Löschen eines Scripts
  const handleDeleteScript = async () => {
    if (!scriptToDelete) return;
    try {
      let response;
      if (isGame) {
        response = await axiosInstance.delete(`/games/${event.id}/scripts/${scriptToDelete}/`);
      } else {
        response = await axiosInstance.delete(`/practices/${event.id}/scripts/${scriptToDelete}/`);
      }
      setScripts((prevScripts) => prevScripts.filter((script) => script.id !== scriptToDelete)); // Entferne das Script aus der lokalen Liste
    } catch (error) {
      console.error('Fehler beim Löschen des Scripts:', error);
    } finally {
      handleCloseDeleteDialog(); // Schließe den Dialog nach Abschluss
    }
  };

  const handleViewScript = (scriptId) => navigate(`/scripts/${scriptId}/plays/`);
  // Formatierung des Datums in deutsches Format
  const formattedDate = format(new Date(event.date), "dd.MM.yyyy 'um' HH:mm", { locale: de });

  return (
    <Card variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        marginBottom: 2,
        borderLeft: `5px solid ${isGame ? '#f44336' : '#4caf50'}`, // Rote Farbe für Games, grün für Practices
        backgroundColor: isGame ? '#fff3f2' : '#f2f9f2',
      }}  >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {isGame ? (
          <SportsSoccerIcon fontSize="large" color="error" />
        ) : (
          <FitnessCenterIcon fontSize="large" color="success" />
        )}
        <Box sx={{ marginLeft: 2 }}>
          <Typography variant="h6">{event.name}</Typography>
          <Typography variant="body1" color="textSecondary">
            {isGame ? `Spiel gegen ${event.opponent}` : `Training für ${event.team.name}`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formattedDate} - {event.location}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Details ansehen
          </Button>
        </Box>
      </Box>
       {/* Scripts */}
       <Typography variant="subtitle1" gutterBottom>
        Scripts
      </Typography>
      <List>
        {scripts.map((script) => (
          <ListItem
            key={script.id}
            secondaryAction={
              <>
                <Button
                  size="small"
                  variant="outlined"
                  color="success"
                  onClick={() => navigate(`/scripts/${script.id}/offense`)}
                >
                  Offense
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  sx={{ ml: 1 }}
                  onClick={() => navigate(`/scripts/${script.id}/defense`)}
                >
                  Defense
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 1 }}
                  onClick={() => navigate(`/scripts/${script.id}/specialteams`)}
                >
                  Special Teams
                </Button>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="error"
                  sx={{ ml: 1 }}
                  onClick={() => handleOpenDeleteDialog(script.id)} 
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={script.name} onClick={() => handleViewScript(script.id)} />
          </ListItem>
        ))}
      </List>

      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={onAddScript}
      >
        Neues Script hinzufügen
      </Button>
      {/* Bestätigungsdialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Script löschen</DialogTitle>
        <DialogContent>
          Möchtest du das Script wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleDeleteScript} color="error" variant="contained">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EventCard;
