// src/pages/ScriptPage.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, CircularProgress } from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import { useParams } from 'react-router-dom';

const ScriptPage = () => {
  const { scriptId } = useParams();
  const [plays, setPlays] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlays = async () => {
      try {
        const response = await axiosInstance.get(`/scripts/${scriptId}/plays/`);
        setPlays(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Plays:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPlays();
  }, [scriptId]);

  const handleAddPlay = async (playType) => {
    try {
      const newPlay = { play_type: playType, play_number: plays.length + 1 };
      const response = await axiosInstance.post(`/scripts/${scriptId}/plays/`, newPlay);
      setPlays((prev) => [...prev, response.data]);
    } catch (error) {
      console.error('Fehler beim Hinzufügen eines Plays:', error);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        LivePlays für Script #{scriptId}
      </Typography>
      <List>
        {plays.map((play) => (
          <ListItem key={play.id}>
            <ListItemText primary={`Play #${play.play_number}`} secondary={`${play.play_type}`} />
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={() => handleAddPlay('Offense')}>
        Neues Offense Play
      </Button>
      <Button variant="contained" color="success" sx={{ ml: 2 }} onClick={() => handleAddPlay('Defense')}>
        Neues Defense Play
      </Button>
      <Button variant="contained" color="error" sx={{ ml: 2 }} onClick={() => handleAddPlay('Special Teams')}>
        Neues Special Teams Play
      </Button>
    </Box>
  );
};

export default ScriptPage;
