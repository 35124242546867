// src/components/EventDetails/PeriodActivityTable.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DataGrid from 'react-data-grid';
import axiosInstance from '../../api/axiosInstance';
import { Button, Box, Typography } from '@mui/material';

const POSITIONS = ["OL", "RB", "WR", "QB", "DL", "LB", "DB"];  // Definiert die Positionen als Spalten

const PeriodActivityTable = () => {
  const { practiceId } = useParams();
  const [periods, setPeriods] = useState([]);
  const [rows, setRows] = useState([]);
  
  useEffect(() => {
    fetchPeriodsAndActivities();
  }, [practiceId]);

  // Holt die Perioden und Aktivitäten vom Server
  const fetchPeriodsAndActivities = async () => {
    try {
      const response = await axiosInstance.get(`/practices/${practiceId}/periods/`);
      const periodsData = response.data;
      
      // Formatieren der Perioden- und Aktivitätsdaten in Zeilen für react-data-grid
      const formattedRows = periodsData.map((period) => {
        const row = {
          id: period.id,
          name: period.name,
          start_time: period.start_time,
          end_time: period.end_time,
        };
        POSITIONS.forEach((position) => {
          const activity = period.activities.find(act => act.position === position);
          row[position] = activity ? activity.description : "";
        });
        return row;
      });
      setPeriods(periodsData);
      setRows(formattedRows);
    } catch (error) {
      console.error("Fehler beim Laden der Perioden und Aktivitäten:", error);
    }
  };

  // Definieren der Spalten für die DataGrid
  const columns = [
    { key: 'name', name: 'Period' },
    { key: 'start_time', name: 'Start Time', editable: true },
    { key: 'end_time', name: 'End Time', editable: true },
    ...POSITIONS.map(position => ({
      key: position,
      name: position,
      editable: true
    })),
  ];

  // Verarbeiten der Änderungen in den Zeilen
  const onRowsChange = (updatedRows) => {
    setRows(updatedRows);
  };

  // Speichern der Änderungen auf dem Server
  const saveChanges = async () => {
    try {
      const updatedPeriods = rows.map(row => {
        const periodData = {
          id: row.id,
          name: row.name,
          start_time: row.start_time,
          end_time: row.end_time,
          activities: POSITIONS.map(position => ({
            position,
            description: row[position],
          })),
        };
        return periodData;
      });
      
      await Promise.all(
        updatedPeriods.map(async (period) => {
          // Speichern der Periode
          await axiosInstance.put(`/practices/${practiceId}/periods/${period.id}/`, {
            name: period.name,
            start_time: period.start_time,
            end_time: period.end_time,
          });
          // Speichern der Aktivitäten innerhalb der Periode
          await Promise.all(
            period.activities.map(activity =>
              axiosInstance.put(`/practice-periods/${period.id}/activities/`, {
                position: activity.position,
                description: activity.description
              })
            )
          );
        })
      );

      alert("Änderungen erfolgreich gespeichert!");
    } catch (error) {
      console.error("Fehler beim Speichern der Änderungen:", error);
      alert("Fehler beim Speichern der Änderungen.");
    }
  };

  return (
    <Box>
      <Typography variant="h5" align="center" gutterBottom>
        Bearbeitbare Practice Schedule Tabelle
      </Typography>
      <DataGrid
        columns={columns}
        rows={rows}
        onRowsChange={onRowsChange}
        rowKeyGetter={row => row.id}
      />
      <Button onClick={saveChanges} variant="contained" color="primary" sx={{ mt: 2 }}>
        Änderungen speichern
      </Button>
    </Box>
  );
};

export default PeriodActivityTable;
