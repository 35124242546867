import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Paper } from '@mui/material';

function OffensePlayForm({ onSubmit }) {
  const [playType, setPlayType] = useState('Run');
  const [offForm, setOffForm] = useState('');
  const [offMotion, setOffMotion] = useState('');
  const [offPlay, setOffPlay] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Daten sammeln
    const playData = {
      play_type: playType,
      off_form: offForm,
      off_motion: offMotion,
      off_play: offPlay,
    };

    // Formular absenden
    onSubmit(playData);

    // Formularfelder leeren
    setPlayType('Run');
    setOffForm('');
    setOffMotion('');
    setOffPlay('');
  };

  return (
    <form onSubmit={handleSubmit} >
      <Typography variant="h4">Offense Play</Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <TextField
            label="Play Type"
            value={playType}
            onChange={(e) => setPlayType(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Offensive Formation"
            value={offForm}
            onChange={(e) => setOffForm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Motion"
            value={offMotion}
            onChange={(e) => setOffMotion(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Offensive Play"
            value={offPlay}
            onChange={(e) => setOffPlay(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save Offense Play
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default OffensePlayForm;
