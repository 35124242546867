import axiosInstance from './axiosInstance';

export const fetchPractices = (teamId) => {
  return axiosInstance.get(`/teams/${teamId}/practices/`);
};

export const fetchPracticeDetail = (teamId, practiceId) => {
  return axiosInstance.get(`/teams/${teamId}/practices/${practiceId}/`);
};

export const createPractice = (teamId, data) => {
  return axiosInstance.post(`/teams/${teamId}/practices/`, data);
};

export const updatePractice = (teamId, practiceId, data) => {
  return axiosInstance.put(`/teams/${teamId}/practices/${practiceId}/`, data);
};

export const deletePractice = (teamId, practiceId) => {
  return axiosInstance.delete(`/teams/${teamId}/practices/${practiceId}/`);
};
