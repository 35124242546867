// LivePracticeTable.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const LivePracticeTable = () => {

  const { practiceId } = useParams();
  const [plays, setPlays] = useState([]);

  useEffect(() => {
    // Daten einmalig laden
    fetchPlays();

    // Periodisches Aktualisieren (optional)
    const interval = setInterval(() => {
      fetchPlays();
    }, 15000); // Alle 5 Sekunden aktualisieren

    // Bereinigung des Intervals
    return () => clearInterval(interval);
  }, [practiceId]);

  const fetchPlays = async () => {
    try {
      const response = await axios.get(`/api/live_practice/practices/${practiceId}/plays/`);
      setPlays(response.data);
    } catch (error) {
      console.error("Fehler beim Laden der Plays:", error);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" align="center">Live Practice Plays</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Play Number</TableCell>
            <TableCell>Play Type</TableCell>
            <TableCell>Off Formation</TableCell>
            <TableCell>Motion</TableCell>
            <TableCell>Off Play</TableCell>
            <TableCell>Def Front</TableCell>
            <TableCell>Blitz</TableCell>
            <TableCell>Coverage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plays.map((play) => (
            <TableRow key={play.id}>
              <TableCell>{play.play_number}</TableCell>
              <TableCell>{play.play_type}</TableCell>
              <TableCell>{play.off_form}</TableCell>
              <TableCell>{play.motion}</TableCell>
              <TableCell>{play.off_play}</TableCell>
              <TableCell>{play.def_front}</TableCell>
              <TableCell>{play.blitz}</TableCell>
              <TableCell>{play.coverage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LivePracticeTable;
