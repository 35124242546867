import React, { useState } from 'react';
import axiosInstance from './api/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { de } from 'date-fns/locale/de';  // Importiere die deutsche Lokalisierung

function PracticeForm({ teamId }) {
    const [name, setName] = useState('');
    const [team, setTeam] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault();
        const practiceData = {
            name,
            team_id: teamId,
            start_time: startTime.toISOString(),
            end_time: endTime.toISOString()
        };
        try{
            const response = await axiosInstance.post(`/live_practice/teams/${teamId}/create-practice/`, practiceData)
            navigate('/practices');  // Redirect to practice list after creation

        } catch (error) {
            console.error("There was an error creating the practice!", error);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                    <Typography variant="h4">Create a New Practice</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Practice Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </Grid>


                <Grid item xs={12}>
                    <DateTimePicker
                        label="Start Time"
                        value={startTime}
                        onChange={(newValue) => setStartTime(newValue)}
                        ampm={false}  // Erzwingt das 24-Stunden-Format
                        views={['day', 'month', 'year', 'hours', 'minutes']}
                        renderInput={(props) => <TextField {...props} fullWidth required />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <DateTimePicker
                        label="End Time"
                        value={endTime}
                        onChange={(newValue) => setEndTime(newValue)}
                        ampm={false}  // Erzwingt das 24-Stunden-Format
                        views={['day', 'month', 'year', 'hours', 'minutes']}
                        renderInput={(props) => <TextField {...props} fullWidth required />}
                    />
                </Grid>

                <Grid item>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSubmit}
                    >
                        Create Practice
                    </Button>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}

export default PracticeForm;
