// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-list-item {
  background-color: #f5f5f5;
  color: #004d40;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/Sidebar.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,iBAAA;AACF","sourcesContent":[".active-list-item {\n  background-color: #f5f5f5;  // Beispielwert für deine Hintergrundfarbe\n  color: #004d40;  // Beispielwert für die Textfarbe\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
