import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OffensePlayForm from './OffensePlayForm';
import DefensePlayForm from './DefensePlayForm';
import { TextField, Button, Grid, Typography, Paper } from '@mui/material';


function LivePractice() {  // playType: 'offense' oder 'defense'
    const { practice_id, playType } = useParams();
    const [socket, setSocket] = useState(null);
    const [playNumber, setPlayNumber] = useState(1);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const ws = new WebSocket(`wss://football-tracker.de/ws/live_practice/${practice_id}/${playType}/`);
        setSocket(ws);

        ws.onopen = () => {
            console.log('WebSocket connected');
            setIsConnected(true);  // Set connection state to true when the connection is established
          };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Received play:', data);

            // Set the play number when the initial connection message is received
            if (data.message === 'connected' && data.current_play_number !== undefined) {
                setPlayNumber(data.current_play_number);
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
      
        ws.onclose = () => {
            console.log('WebSocket closed');
            setIsConnected(false);
        };

        return () => ws.close();
    }, [practice_id, playType]);

    const handlePlaySubmit = (playData) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({
                play_type: playType,
                play: {
                    ...playData,
                    play_number: playNumber
                }
            }));
            setPlayNumber((prevPlayNumber) => prevPlayNumber + 1);        }
    };

    if (!isConnected) {
        return <div>Loading connection...</div>;
      }
    
      if (playNumber === null) {
        return <div>Loading play number...</div>;
      }
    

    return (
        <div className="is-justify-content-space-between p-4">
            <Typography variant="h3">Live Practice for {playType === 'offense' ? "Offense" : "Defense"}</Typography>
            <Paper sx={{ p: 2 }} className="mt-4">
                {playType === 'offense' ? (
                    <OffensePlayForm onSubmit={handlePlaySubmit} />
                ) : (
                    <DefensePlayForm onSubmit={handlePlaySubmit} />
                )}
            </Paper>
        </div>
    );
}

export default LivePractice;
