import axiosInstance from './axiosInstance';

export const fetchGames = (teamId) => {
  return axiosInstance.get(`/teams/${teamId}/games/`);
};

export const fetchGameDetail = (teamId, gameId) => {
  return axiosInstance.get(`/teams/${teamId}/games/${gameId}/`);
};

export const createGame = (teamId, data) => {
  return axiosInstance.post(`/teams/${teamId}/games/`, data);
};

export const updateGame = (teamId, gameId, data) => {
  return axiosInstance.put(`/teams/${teamId}/games/${gameId}/`, data);
};

export const deleteGame = (teamId, gameId) => {
  return axiosInstance.delete(`/teams/${teamId}/games/${gameId}/`);
};
