// src/components/Timeline/AddEventModal.js
import React, { useState } from 'react';
import { Modal, Box, TextField, Button, MenuItem, Select, InputLabel } from '@mui/material';

const AddEventModal = ({ open, onClose, onSave }) => {
  const [eventType, setEventType] = useState('Practice');
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [opponent, setOpponent] = useState('');

  const handleSave = () => {
    const newEvent = { eventType, date, location };
    if (eventType === 'Game') newEvent.opponent = opponent;
    onSave(newEvent);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: 4, backgroundColor: 'white', borderRadius: 1, maxWidth: 400, margin: 'auto', marginTop: '10%' }}>
        <InputLabel>Event Type</InputLabel>
        <Select value={eventType} onChange={(e) => setEventType(e.target.value)} fullWidth>
          <MenuItem value="Practice">Practice</MenuItem>
          <MenuItem value="Game">Game</MenuItem>
        </Select>
        <TextField label="Date" type="date" value={date} onChange={(e) => setDate(e.target.value)} fullWidth sx={{ marginTop: 2 }} />
        <TextField label="Location" value={location} onChange={(e) => setLocation(e.target.value)} fullWidth sx={{ marginTop: 2 }} />
        {eventType === 'Game' && <TextField label="Opponent" value={opponent} onChange={(e) => setOpponent(e.target.value)} fullWidth sx={{ marginTop: 2 }} />}
        <Button variant="contained" onClick={handleSave} sx={{ marginTop: 3 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AddEventModal;
