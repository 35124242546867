import React, { useState, useEffect } from 'react';
import axiosInstance from './api/axiosInstance';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Chip, IconButton, Select, MenuItem, InputLabel, FormControl } from '@mui/material';


function PracticeManagement({ organisationId, teamId }) {
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [practices, setPractices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Fetch all teams where the user is "Coach" or "Staff"
    useEffect(() => {
        fetchPractices();
    }, []);

    // Fetch practices for the selected team
    const fetchPractices = async () => {
        setLoading(true);
            
        try {
            const response = await axiosInstance.get(`/live_practice/teams/${teamId}/practices/`);
            setPractices(response.data);
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch users.');
            setLoading(false);
        }
        
    }; 

    const handlePracticeClick = (practice_id) => {
        // Navigiere zur LivePracticeTable-Komponente mit dem ausgewählten practice_id
        navigate(`/practices/${practice_id}`);
      };

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) return <p>{error}</p>;

    return (
        <div className="is-justify-content-space-between p-4">
            <h1>Practice Management</h1>
            <TableContainer component={Paper} className="mt-4">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Practice Name</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {practices.map(practice => (
                            <TableRow key={practice.id}>
                                <TableCell><a onClick={() => handlePracticeClick(practice.id)} >{practice.name}</a></TableCell>
                                <TableCell>{new Date(practice.start_time).toLocaleString()}</TableCell>
                                <TableCell>{practice.end_time ? new Date(practice.end_time).toLocaleString() : "Ongoing"}</TableCell>
                                <TableCell>
                                    <Link to={`/practice/${practice.id}/offense`}>Join as Offense</Link> | 
                                    <Link to={`/practice/${practice.id}/defense`}>Join as Defense</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <Link to="/create_practice">Create New Practice</Link>
        </div>
    );
}

export default PracticeManagement;
