import React, { useState } from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';

function DefensePlayForm({ onSubmit }) {
  const [defFront, setDefFront] = useState('');
  const [defBlitz, setDefBlitz] = useState('');
  const [defCoverage, setDefCoverage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Daten sammeln
    const playData = {
      def_front: defFront,
      def_blitz: defBlitz,
      def_coverage: defCoverage,
    };

    // Formular absenden
    onSubmit(playData);

    // Formularfelder leeren
    setDefFront('');
    setDefBlitz('');
    setDefCoverage('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h4">Offense Play</Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <TextField
            label="Defensive Front"
            value={defFront}
            onChange={(e) => setDefFront(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Blitz"
            value={defBlitz}
            onChange={(e) => setDefBlitz(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Coverage"
            value={defCoverage}
            onChange={(e) => setDefCoverage(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save Defense Play
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default DefensePlayForm;
