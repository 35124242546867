import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const ContainerDashboard = ({ children }) => {
    return (
          <Paper 
            elevation={3} 
            sx={{ 
              height: '100%', 
              borderRadius: '16px', 
              display: 'flex',
              padding: 1,
              backgroundColor: '#fff', }}
          >
            <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
              { children }
            </Box>
             
          </Paper>
    );
};

export default ContainerDashboard;