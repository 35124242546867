// AddTagModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem, Select } from '@mui/material';
import axiosInstance from '../api/axiosInstance';

const AddTagModal = ({ open, onClose, onAddOrEditTag, existingTag, organisationId, unit, preselectedType }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [allTypes, setAllTypes] = useState([]);

  const isEditMode = Boolean(existingTag);

  useEffect(() => {
    if (isEditMode && existingTag) {
      setName(existingTag.name);
      setDescription(existingTag.description || '');
      setSelectedType(existingTag.type || '');
    } else {
      setName('');
      setDescription('');
      setSelectedType(preselectedType || '');
    }
  }, [existingTag, isEditMode, preselectedType]);

  useEffect(() => {
    // Laden der verfügbaren Playbook Types von der API
    fetchPlaybookTypes();
  }, [organisationId]);

  const fetchPlaybookTypes = async () => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${organisationId}/playbook-types/?unit=${unit}`);
      setAllTypes(response.data);
    } catch (error) {
      console.error("Error fetching playbook types:", error);
    }
  };

  const handleSubmit = () => {
    const tagData = {
      name,
      description,
      type: selectedType,
    };
    onAddOrEditTag(tagData);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ width: 400, padding: 2, backgroundColor: 'white', margin: 'auto', marginTop: '10%', borderRadius: 2, boxShadow: 24 }}>
        <Typography variant="h6" component="h2">
          {isEditMode ? 'Edit Tag' : 'Add New Tag'}
        </Typography>

        <TextField
          fullWidth
          label="Tag Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ marginTop: 2 }}
        />
        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginTop: 2 }}
        />

        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Select Playbook Type:
        </Typography>
        <Select
          fullWidth
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          sx={{ marginTop: 1 }}
        >
          {allTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 2 }}>
          {isEditMode ? 'Save Changes' : 'Add Tag'}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddTagModal;
