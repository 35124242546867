// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004d40', // Jungle/Olive Grün
      light: '#357a38', // Eine hellere Version davon
    },
    secondary: {
      main: '#a1887f', // Dezentes Sand- oder Braun-Ton
    },
    error: {
      main: '#e57373', // Rötlich
    },
    background: {
      default: '#f5f5f5',
    },
    text: {
      primary: '#333333', // Dunkelgrau
      secondary: '#7D7D7D', // Mittelgrau
    },
    action: {
      hover: '#E0E0E0', // Hover-Hintergrund
    },
  },
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Rundere Ecken für Buttons
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px',
          borderRadius: '12px',
        },
      },
    },
  },
});

export default theme;
