// src/hooks/useFetchGames.js

import { useState, useEffect } from 'react';
import { fetchGames } from '../api/gamesApi';

const useFetchGames = (teamId) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllGames = async () => {
    if (!teamId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetchGames(teamId);
      setGames(response.data);
    } catch (err) {
      setError('Fehler beim Laden der Games');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGames();
  }, [teamId]);

  return { games, loading, error, refreshGames: fetchAllGames };
};

export default useFetchGames;
