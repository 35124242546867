// src/hooks/useFetchPractices.js

import { useState, useEffect } from 'react';
import { fetchPractices } from '../api/practicesApi';

const useFetchPractices = (teamId) => {
  const [practices, setPractices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllPractices = async () => {
    if (!teamId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetchPractices(teamId);
      setPractices(response.data);
    } catch (err) {
      setError('Fehler beim Laden der Practices');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPractices();
  }, [teamId]);

  return { practices, loading, error, refreshPractices: fetchAllPractices };
};

export default useFetchPractices;
