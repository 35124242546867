import React, { useState } from 'react';
import { Stage, Layer, Line, Circle, Text } from 'react-konva';
import { Button, Box } from '@mui/material';

const PlayDiagram = () => {
  const [shapes, setShapes] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  // Spieler hinzufügen
  const addPlayer = (x = 100, y = 100) => {
    setShapes([
      ...shapes,
      {
        type: 'player',
        x,
        y,
        id: `player-${shapes.length + 1}`,
      },
    ]);
  };

  // Startet das Zeichnen eines Pfads
  const handleMouseDown = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setShapes([
      ...shapes,
      {
        type: 'line',
        points: [x, y],
        id: `line-${shapes.length + 1}`,
      },
    ]);
    setIsDrawing(true);
  };

  // Aktualisiert den Pfad
  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const { x, y } = e.target.getStage().getPointerPosition();
    const lastShape = shapes[shapes.length - 1];
    if (lastShape.type === 'line') {
      const updatedShapes = shapes.slice(0, -1);
      updatedShapes.push({
        ...lastShape,
        points: [...lastShape.points, x, y],
      });
      setShapes(updatedShapes);
    }
  };

  // Zeichnen beenden
  const handleMouseUp = () => setIsDrawing(false);

  return (
    <Box sx={{ padding: 2 }}>
      <Button variant="contained" onClick={() => addPlayer()}>
        Spieler hinzufügen
      </Button>
      <Stage
        width={800}
        height={600}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ background: '#3e8e41', marginTop: '10px', borderRadius: '10px' }}
      >
        <Layer>
          {shapes.map((shape, i) => {
            if (shape.type === 'player') {
              return (
                <Circle
                  key={shape.id}
                  x={shape.x}
                  y={shape.y}
                  radius={15}
                  fill="white"
                  stroke="black"
                  strokeWidth={2}
                  draggable
                  onDragEnd={(e) => {
                    const updatedShapes = shapes.map((s) =>
                      s.id === shape.id
                        ? { ...s, x: e.target.x(), y: e.target.y() }
                        : s
                    );
                    setShapes(updatedShapes);
                  }}
                />
              );
            } else if (shape.type === 'line') {
              return (
                <Line
                  key={shape.id}
                  points={shape.points}
                  stroke="yellow"
                  strokeWidth={3}
                  lineCap="round"
                  lineJoin="round"
                />
              );
            }
            return null;
          })}
        </Layer>
      </Stage>
    </Box>
  );
};

export default PlayDiagram;
